import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
//import * as headerStyles from '../styles/head.module.css'

const Header = () => (
  <Layout>
    <SEO
      title="Page Three"
      description="Page three description"
    />

    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default Header;
